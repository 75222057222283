<template>
  <CrmForm
    @validate="validate"
    @save="createCrm"
    :crm="crm"
    :valid="isFormValid"
    :type="'create'"
    ref="form"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CrmForm: () => import('./CrmForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    crm: {},
    askToLeave: true,
  }),
  methods: {
    validate(value) {
      this.isFormValid = value
    },
    createCrm() {
      this.$refs.form.validate()

      if (this.isFormValid) {
        this.askToLeave = false
        this.$api
          .post('crm/store', this.crm)
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Usuário cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'crm' })
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar cadastrar este usuário',
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este usuário',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
